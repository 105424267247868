<template>
    <div class="home">
        <div class="home__introduction">
            <div class="home__introduction--text">
                <i class="fas fa-quote-right home__introduction--quotationmark"></i>
                <div class="home__introduction--quote">
                    <p v-html="introduction"></p>
                </div>
                <div class="home__introduction--avatar-container">
                    <a href="https://arminnovacek.com" target="_blank" class="no-animation">
                        <img class="home__introduction--avatar" src="@/assets/images/logo.png" alt="Website Logo" />
                    </a>
                    <SocialIcons style-class="home__introduction--socials" />
                </div>
            </div>
            <img
                class="home__introduction--image"
                src="@/assets/images/home/introduction-picture-alternative.webp"
                alt="Armin Novacek"
                draggable="false"
            />
        </div>

        <div class="home__skills">
            <!-- <img
                class="home__skills--image"
                src="@/assets/images/home/skills-picture-alternative.png"
                alt="Armin Novacek"
                draggable="false"
            /> -->
            <video class="home__skills--video" width="1280" height="720" autoplay muted playsinline loop>
                <source src="https://arminnovacek.com/media/while_working.mp4" type="video/mp4" />
            </video>
            <div class="home__skills--text">
                <ul class="home__skills--list">
                    <li>{{ $t("home.thatsMe") }}</li>
                    <li>
                        <h1>{{ $t("home.skills") }}</h1>
                    </li>
                    <li>
                        <h2>{{ $t("home.developer") }}</h2>
                    </li>
                    <li>{{ $t("home.webDevelopment") }}</li>
                    <li>{{ $t("home.appDevelopment") }}</li>
                    <li><h2>Designer</h2></li>
                    <li>{{ $t("home.webDesign") }}</li>
                    <li>{{ $t("home.graphicDesign") }}</li>
                    <li>{{ $t("home.uxUiDesign") }}</li>
                    <li>Corporate Design & Branding</li>
                </ul>
                <router-link to="/portfolio" class="home__button no-animation">
                    <span class="home__button--label">{{ $t("home.myPortfolio") }}</span>
                </router-link>
            </div>
        </div>

        <div class="home__hustle">
            <div class="home__hustle--list">
                <h3 class="home__hustle--title">My everyday Hustle.</h3>
                <h2>
                    {{ $t("home.frontendDevelopment") }}
                    <span class="home__hustle--item-info">{{ $t("home.atSdsAndPresse") }}</span>
                </h2>
                <h2>
                    Design
                    <span class="home__hustle--item-info">{{ $t("home.ofWebAndGraphic") }}</span>
                </h2>
                <h2>
                    {{ $t("home.commitment") }}
                    <span class="home__hustle--item-info">{{ $t("home.atWorkAndPrivate") }}</span>
                </h2>
                <p class="home__hustle--hobbies">
                    <TypeWriter
                        :words="['#programming #design #fitness #photography #music #helloworld!']"
                        :runtime="90"
                    />
                </p>
                <router-link to="/lebenslauf" class="home__button no-animation">
                    <span class="home__button--label">{{ $t("home.myCv") }}</span>
                </router-link>
            </div>
        </div>

        <div class="home__projects">
            <h1 class="home__projects--title">{{ $t("home.myProjects") }}</h1>
            <p class="home__projects--introduction">
                {{ $t("home.projectsIntro1") }}
                <br />
                {{ $t("home.projectsIntro2") }}
            </p>
            <project-carousel />
        </div>
    </div>
</template>

<script>
import TypeWriter from "../components/TypeWriter";
import SocialIcons from "../components/SocialIcons.vue";
import ProjectCarousel from "../components/ProjectCarousel.vue";

export default {
    name: "Home",
    components: {
        TypeWriter,
        SocialIcons,
        ProjectCarousel,
    },
    computed: {
        introduction() {
            const styles = 'font-weight:bold;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;';
            return (
                this.getGreeting() +
                this.$t("home.intro1") +
                `<span style=${styles}>Armin Novacek</span>` +
                this.$t("home.intro2") +
                `<span style=${styles}>` +
                this.$t("home.intro3") +
                "</span>" +
                this.$t("home.intro4")
            );
        },
    },
    mounted() {
        this.$nextTick(() => {
            // set animations for window scroll
            this.setScrollAnim([".home__skills--text", ".home__projects", ".home__hustle--list"]);
        });
    },
    methods: {
        getGreeting() {
            const hourOfDay = new Date().getHours();
            switch (true) {
                case hourOfDay >= 5 && hourOfDay < 11:
                    return this.$t("home.goodMorning");
                case hourOfDay >= 11 && hourOfDay < 18:
                    return this.$t("home.goodDay");
                case hourOfDay >= 18 && hourOfDay < 22:
                    return this.$t("home.goodEvening");
                default:
                    return this.$t("home.helloNightOwl");
            }
        },
    },
};
</script>

<style scoped>
/* general */
.home__button {
    border-radius: 5px;
    background-image: var(--rainbow);
    border: none;
    color: var(--background);
    text-align: center;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
}
.home__button:hover {
    filter: brightness(1.05);
}

/* introduction */
.home__introduction {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}
.home__introduction--text {
    position: absolute;
    width: 60%;
    max-width: 620px;
}
.home__introduction--image {
    display: block;
    margin-left: auto;
    width: 60%;
    height: 0%;
    border-radius: 15px;
}
.home__introduction--quotationmark {
    font-size: 40px;
    top: -40px;
    left: 12px;
    position: absolute;
    background-image: var(--rainbow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.home__introduction--quote {
    font-size: var(--font-size-large);
    background-color: var(--background-opacity);
    border-radius: 15px;
    padding: 12px 18px;
}
.home__introduction--quote > p {
    margin: 0;
    text-wrap: balance;
}
.home__introduction--avatar-container {
    font-size: var(--font-size-small);
    display: flex;
    padding: 10px 18px;
    gap: 20px;
}
.home__introduction--avatar {
    width: 22px;
    height: 22px;
    margin: auto 0;
}
.home__introduction--avatar:hover {
    opacity: 0.7;
}
@media (max-width: 900px) {
    .home__introduction--image {
        width: 100%;
    }
    .home__introduction--text {
        position: relative;
        margin-top: 25px;
        width: 100%;
    }
}

/* skills */
.home__skills {
    padding-top: 100px;
    display: flex;
}
.home__skills--image,
.home__skills--video {
    width: 50%;
    height: max-content;
    border-radius: 15px;
}
.home__skills--text {
    margin: 50px auto 50px auto;
}
.home__skills--list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 50px;
    color: var(--text-dark);
}
.home__skills--list h1,
.home__skills--list h2 {
    color: var(--text-light);
}
@media (max-width: 880px) {
    .home__skills--text {
        margin: 0 auto 100px auto;
    }
}
@media (max-width: 780px) {
    .home__skills {
        flex-direction: row-reverse;
    }
    .home__skills--image,
    .home__skills--video {
        width: 40%;
        margin-top: 65px;
    }
    .home__skills--text {
        margin-left: 0;
    }
}
@media (max-width: 600px) {
    .home__skills--image,
    .home__skills--video {
        width: 100%;
        margin: 0 0 50px;
    }
    .home__skills {
        flex-direction: column;
    }
}

/* hustle */
.home__hustle {
    background-color: var(--emo-gray);
    border: 1px solid var(--item-border);
    border-radius: 15px;
    margin-top: -50px;
    max-width: 900px;
    width: 100%;
    margin-left: -10%;
    padding: 80px 10% 75px;
}
.home__hustle--title {
    color: var(--text-dark);
}
.home__hustle--item-info {
    font-family: var(--main-font);
    letter-spacing: normal;
    color: var(--text-dark);
    font-size: var(--font-size-medium);
}
.home__hustle--hobbies {
    font-style: italic;
    margin-bottom: 35px;
    color: var(--text-dark);
}
@media (max-width: 500px) {
    .home__hustle {
        margin-left: -5%;
        padding: 80px 5% 75px;
        border-radius: 0;
    }
}

/* projects */
.home__projects {
    margin-top: 50px;
}
.home__projects--introduction {
    color: var(--text-dark);
}
</style>
